import {type HttpEvent, type HttpInterceptorFn, HttpResponse} from '@angular/common/http';
import {map} from 'rxjs';
import {inject} from "@angular/core";
import {EnvironmentService} from "@services/environment.service";

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  const environmentService = inject(EnvironmentService);
  let token: string | null = localStorage.getItem('tknStore');
  token = token && token.toString().trim() === 'null' ? null : token;

  let modifiedRequest = req.clone({
    headers: req.headers
      .set('Accept', 'application/json, text/plain, */*')
      .set('Content-Type', 'application/json')
      .set('client', environmentService.getNameMultiClient()),
  });

  if (token) {
    modifiedRequest = modifiedRequest.clone({headers: modifiedRequest.headers.set('token2X', token)});
  }

  return next(modifiedRequest).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (event.status === 200) {
          const timeExpiration: number =
            Number(event.headers.get('tknStore-expiration')) + Number(new Date().getTime());
            localStorage.setItem('tknStore', event.headers.get('token2X') ?? '');
            localStorage.setItem('tknStore-expiration', JSON.stringify(timeExpiration));
        }
      }
      return event;
    })
  );
};
