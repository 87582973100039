import type { HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error) => {
      if ([401, 403].includes(error.status)) {
        console.log('Unauthorized request');
        //accountService.logout()
      } else if ([404].includes(error.status)) {
        console.log('Not Found');
      }

      const e = error.error.message || error.statusText;
      console.error(e.message);
      return throwError(() => error)
    })
  )
};
