import { CommonModule } from '@angular/common';
import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import { version} from '../../../../package.json'
import {EnvironmentService} from "@services/environment.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  private environmentService = inject(EnvironmentService);
  public phoneNumber = this.environmentService.getPhoneNumber();
  public storeVersion = version;
}
