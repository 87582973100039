import {computed, Injectable, signal, WritableSignal} from "@angular/core";
import {environment} from "../../environments/environment";
import {BucketConfig} from "@interfaces/general/bucket-config";
import {EnvironmentHtmlContent} from "@interfaces/general/environment-html-content";
import {EnvironmentMercadoPago} from "@interfaces/general/environment-mercado-pago";

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {

  getNameMultiClient() {
    return environment.general.nameMultiClient;
  }
  getLogoUrl() {
    return environment.general.logoUrl;
  }
  getCarouselImages() {
    return environment.general.carouselImages;
  }
  getPhoneNumber() {
    return environment.general.phoneNumber;
  }
  getColumnsItemMobile() {
    return environment.general.columnsItemMobile;
  }
  getUrl() {
    return environment.general.url;
  }

  getBucketConfig(): BucketConfig {
    return environment.bucketConfig;
  }
  getHtmlContent(): EnvironmentHtmlContent {
    return environment.htmlContent;
  }
  getMercadoPago(): EnvironmentMercadoPago {
    return environment.mercadoPago;
  }
}
