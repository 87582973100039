import { Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';

export const routes: Routes = [
    {
        path: 'store',
        loadComponent: () => import('./main/main.component'),
        children: [
          {
            path: 'products',
            title: 'Productos',
            loadComponent: () => import('./main/pages/items/items.component'),
          },
          {
            path: 'products/:id',
            title: 'Producto',
            loadComponent: () => import('./main/pages/items/items.component'),
          },
          {
            path: 'search/:name',
            title: 'Busqueda',
            loadComponent: () => import('./main/pages/search/search.component'),
          },
          {
            path: 'item/:id',
            title: 'Item',
            loadComponent: () => import('./main/pages/item/item.component'),
          },
          {
            path: 'cart',
            title: 'Carrito',
            loadComponent: () => import('./main/pages/cart/cart.component'),
          },
          {
            path: 'finish',
            title: 'Finalizar compra',
            loadComponent: () => import('./main/pages/finish-purchase/finish-purchase.component'),
          },
          {
            path: 'login',
            title: 'Login',
            loadComponent: () => import('./main/pages/auth/login/login.component'),
          },
          {
            path: 'register',
            title: 'Registro',
            loadComponent: () => import('./main/pages/auth/register/register.component'),
          },
          {
            path: 'profile/:id',
            title: 'Perfil',
            loadComponent: () => import('./main/pages/profile/profile.component')
          },   
          {
            path:'', redirectTo: 'products', pathMatch: 'full',
          }
        ]
      },
      {
        path: '',
        redirectTo: '/store',
        pathMatch: 'full'
      }
];
